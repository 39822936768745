<template>
    <div class="kustomer-header">
        <!-- <div class="kustomer-logo">
            <slot name="logo"></slot>
        </div> -->
        <slot name="title"></slot>
    </div>
</template>

<script>
export default {}
</script>
