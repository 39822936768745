<template>

</template>

<script>
export default {
    name: 'homeslider',
    data(){
        return{
        slides: this.getSlider()
        }
    },
    methods:{
        getSlider: function(){
            //const baseURI = window.location.origin+'/slides'
            const baseURI = 'http://localhost/appmr/public/slides';
            let result = this.$http.get(baseURI).then(result=>{
            if(result.status==200){
                this.slides=result.data;
            }
            }).catch(err=>{
                console.log(err)
            });
        }
    },
    mounted(){
        //this.getSlider();
        // window.jQuery('.flexslider').flexslider({
        //     animation: "fade"
        // });
    }
}
</script>
<style lang="sass">
    @import "~flexslider/flexslider.css"
    .flexslider
        width: 100%
        height:465px

    .flexslider .slides > li
        display: block
        -webkit-backface-visibility: hidden

</style>

