<template>
</template>
<script>
    export default {
        mounted() {
            let recaptchaScript = document.createElement('script')
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });

            //Jquery File Upload

            $(function () {
                $('#local_content_file').fileupload({
                    dataType: 'json',
                    add: function (e, data) {
                        $('#loading').text('Uploading...');
                        data.formData = {company_id:$("input[name=company_id]").val(),doc_type:'policy'};
                        data.submit();
                    },
                    done: function (e, data) {
                        $.each(data.result.files, function (index, file) {
                            $('<a href="'+file.path+'"/>').html(file.name + ' (' + file.size + ' KB)').appendTo($('#files_list'));
                            if ($('#file_ids').val() != '') {
                                $('#file_ids').val($('#file_ids').val() + ',');
                            }
                            $('#file_ids').val($('#file_ids').val() + file.fileID);
                            $('#file_names').val($('#file_names').val() + file.name);
                        });
                        $('#loading').text('');
                    }
                });

                $('#contract_file').fileupload({
                    dataType: 'json',
                    add: function (e, data) {
                        $('#loading').text('Uploading...');
                        data.formData = {company_id:$("input[name=company_id]").val(),doc_type:'reference'};
                        data.submit();
                    },
                    done: function (e, data) {
                        $.each(data.result.files, function (index, file) {
                            $('<a href="'+file.path+'"/>').html(file.name + ' (' + file.size + ' KB)').appendTo($('#files_list'));
                            if ($('#file_ids').val() != '') {
                                $('#file_ids').val($('#file_ids').val() + ',');
                            }
                            $('#file_ids').val($('#file_ids').val() + file.fileID);
                            $('#file_names').val($('#file_names').val() + file.name);
                        });
                        $('#loading').text('');
                    }
                });
                //To handle uploading of company documentation via ajax
                //var formData = $('form').serializeArray();
                $('.fileupload').each(function(){
                    $(this).fileupload({
                        dataType: 'json',
                        dropZone: $(this),
                        add: function (e, data) {
                            $(this).find('#loading').text('Uploading...');
                            //formData:{company:$("input[name=company_id]").val()};
                            data.submit();
                        },
                        done: function (e, data) {
                            $.each(data.result.files, function (index, file) {
                                $('<a href="'+file.path+'"/>').html(file.name + ' (' + file.size + ' KB)').appendTo($(this).find('#files_list'));
                                if ($(this).find('#file_ids').val() != '') {
                                    $(this).find('#file_ids').val($('#file_ids').val() + ',');
                                }
                                $(this).find('#file_ids').val($('#file_ids').val() + file.fileID);
                                $(this).find('#file_names').val($('#file_names').val() + file.name);
                                $(this).find('#document_number').val(file.document_number);
                                $(this).find("#document_number" ).prop( "disabled", true );
                                $(this).find('#issued_by').val(file.issued_by);
                                $(this).find("#issued_by" ).prop( "disabled", true );
                                $(this).find('#issue_date').val(file.issued_date);
                                $(this).find("#issued_date" ).prop( "disabled", true );
                                $(this).find('#expiry_date').val(file.expiry_date);
                                $(this).find("#expiry_date" ).prop( "disabled", true );
                            });
                            $(this).find('#loading').text('Uploaded! Please use link at top of this page to View Uploaded Documents.');
                        }
                    });
                });
            });

            //Add contact
            $(".btn-add").click(function(e){
                e.preventDefault();
                var name = $("input[name=name]").val();
                var designation = $("input[name=designation]").val();
                var email = $("input[name=email]").val();
                var ctelephone = $("input[name=ctelephone]").val();
                var address = $("input[name=address]").val();
                var company_id = $("input[name=company_id]").val();
                $.ajax({
                type:'POST',
                url:$("input[name=get_uc]").val(),
                data:{name:name, designation:designation, email:email, ctelephone:ctelephone, address:address, company:company_id},
                success:function(data){
                    alert(data.success);
                    $('#contacts-table tbody').append(data.contact);
                    $('#companyContactModal').modal('hide');
                    $('#companyContactModal').on('hidden.bs.modal', function (e) {
                        $(this).removeData('bs.modal');
                    })
                }
                });
            });

            //Delete Contact
            // $(".btn-delete").click(function(e){
            //     e.preventDefault();
            //     var contact_id = $("input[name=contact_id]").val();
            //     $.ajax({
            //     type:'GET',
            //     url:$("input[name=get_udc]").val(),
            //     data:{contact_id:contact_id},
            //     success:function(data){
            //         alert(data.success);
            //         location.reload();
            //     }
            //     });
            // });

            //Save Section A
            $(".btn-save-section").click(function(e){
                e.preventDefault();
                var name = $("input[name=company_name]").val();
                var description = $("#description").val();
                var company_registration = $("input[name=company_registration]").val();
                var tier = $("#tier").val();
                var telephone = $("input[name=telephone]").val();
                var year_of_registration = $("input[name=year_of_registration]").val();
                var website = $("input[name=website]").val();
                var number_of_branches = $("input[name=no_of_branches]").val();
                var address = $("#address").val();
                var company_id = $("input[name=company_id]").val();
                var sector = $("#sector").val();
                $.ajax({
                type:'POST',
                url:$("input[name=get_fsection]").val(),
                data:{company_name:name, desc:description, company_registration:company_registration, telephone:telephone, address:address, tier:tier, company:company_id, sector:sector, year_of_registration:year_of_registration, website:website, number_of_branches:number_of_branches},
                success:function(data){
                    alert(data.success);
                    window.location.href = 'company-structure-ownership',true;
                }
                });
            });

             //Add Director
            $(".btn-add-director").click(function(e){
                e.preventDefault();
                var form = $("#add-director-form")[0];
                if(!form.checkValidity()){
                    form.reportValidity()
                }
                else{
                    var data = new FormData(form)
                    $.ajax({
                        type:'POST',
                        url:$("input[name=get_udirector]").val(),
                        enctype: 'multipart/form-data',
                        data: data,
                        processData: false,
                        contentType: false,
                        success:function(data){
                            if(data.status){
                                new Noty({
                                    type:'success',
                                    layout:'top',
                                    text: data.success,
                                    timeout:10000
                                }).show();
                                location.reload();
                            }
                            else{
                                new Noty({
                                    type:'error',
                                    layout:'top',
                                    text: 'Error Adding Director',
                                    timeout:10000
                                }).show();
                                location.reload();
                            }
                        }
                    });
                }
            });

            //Add Shareholder
            // $(".btn-add-shareholder").click(function(e){
            //     e.preventDefault();
            //     var shareholder_name = $("input[name=shareholder_name]").val();
            //     var nationality = $("#nationality").val();
            //     var shares = $("#shares").val();
            //     var company_id = $("input[name=company_id]").val();
            //     $.ajax({
            //     type:'POST',
            //     url:$("input[name=get_ushareholding]").val(),
            //     data:{shareholder_name:shareholder_name, nationality:nationality, shares:shares, company:company_id},
            //     success:function(data){
            //         alert(data.success);
            //         location.reload();
            //     }
            //     });
            // });

              //Add Reference
            // $(".btn-add-reference").click(function(e){
            //     e.preventDefault();
            //     var client = $("input[name=client]").val();
            //     var contact_person = $("#contact_person").val();
            //     var designation = $("#designation").val();
            //     var contact_info = $("#contact_info").val();
            //     var contract_value = $("#value_of_contract").val();
            //     var description = $("#description").val();
            //     var contract_file = $("#file_names").val();
            //     var vcurrency = $("#vcontract_currency").val();
            //     var company_id = $("input[name=company_id]").val();
            //     $.ajax({
            //     type:'POST',
            //     url:$("input[name=get_ureference]").val(),
            //     data:{contact_person:contact_person, client:client, designation:designation, contact_info:contact_info, contract_value:contract_value, description:description, contract_file:contract_file, vcurrency:vcurrency,company:company_id},
            //     success:function(data){
            //         alert(data.success);
            //         location.reload();
            //     }
            //     });
            // });


             //Save Company Structure
            $(".btn-save-section-two").click(function(e){
                e.preventDefault();
                //TODO Get owenrship percetange values
                var number_of_employees = $("input[name=number_of_employees]").val();
                var employ_disabled = $("#employ_disabled").val();
                var local_content_policy = $("#local_content_policy").val();
                var local_content_file = $("#file_names").val();
                var ownership_women = $("#ownership_women").val();
                var ownership_youth = $("#ownership_youth").val();
                var company_id = $("input[name=company_id]").val();
                $.ajax({
                type:'POST',
                url:$("input[name=get_fsection]").val(),
                data:{number_of_employees:number_of_employees, employ_disabled:employ_disabled, local_content_policy:local_content_policy, local_content_file:local_content_file, ownership_women:ownership_women, ownership_youth:ownership_youth, company:company_id},
                    success:function(data){
                        new Noty({
                                type:'success',
                                layout:'top',
                                text: data.success,
                                timeout:10000
                            }).show();
                    }
                });
            });

              //Save Section C
            $(".btn-save-section-three").click(function(e){
                e.preventDefault();
                var approx_turnover = $("#approx_turnover").val();
                var credit_checks = $("#credit_checks").val();
                var company_id = $("input[name=company_id]").val();
                $.ajax({
                type:'POST',
                url:$("input[name=get_fsection]").val(),
                data:{approx_turnover:approx_turnover, credit_checks:credit_checks, company:company_id},
                success:function(data){
                    new Noty({
                            type:'success',
                            layout:'top',
                            text: data.success,
                            timeout:10000
                        }).show();
                }
                });
            });

            //Save Section D
            $(".btn-save-section-four").click(function(e){
                e.preventDefault();
                var company_id = $("input[name=company_id]").val();
                $.ajax({
                type:'POST',
                url:$("input[name=get_fsection]").val(),
                data:{company:company_id},
                success:function(data){
                    new Noty({
                            type:'success',
                            layout:'top',
                            text: data.success,
                            timeout:10000
                        }).show();
                }
                });
            });

            //Save Section E
            $(".btn-save-section-five").click(function(e){
                e.preventDefault();
                var company_id = $("input[name=company_id]").val();
                var debarred = $("#debarred").val();
                var conflict_of_interest = $("#conflict_of_interest").val();
                var conflict_of_interest_iia = $("#conflict_of_interest_iia").val();
                var declare_true = $("#declare_true").val();
                var understand_risks = $("#understand_risks").val();
                $.ajax({
                type:'POST',
                url:$("input[name=get_fsection]").val(),
                data:{company:company_id,debarred:debarred,conflict_of_interest:conflict_of_interest,conflict_of_interest_iia:conflict_of_interest_iia,declare_true:declare_true,understand_risks:understand_risks},
                success:function(data){
                    new Noty({
                            type:'success',
                            layout:'top',
                            text: data.success,
                            timeout:10000
                        }).show();
                }
                });
            });

            //Publish Request
            $(".btn-publishing-request").hide();
            $(".btn-publish-request").click(function(e){
                e.preventDefault();
                $(this).hide();
                $(".btn-publishing-request").show();
                var request_type = $("#request_type").val();
                var summary = $("#summary").val();
                var description = $("#description").val();
                var request_name = $("#request_name").val();
                var deadline = $("#deadline").val();
                var sector = $("#request_sector").val();
                var submission_instructions = $("#submission_instructions").val();
                var buyer = $("#buyer").val();
                var company_id = $("#company_id").val();
                var member = $("#member").val();
                var request = $("#request").val();
                $.ajax({
                    type:'POST',
                    url:$("input[name=get_publish_url]").val(),
                    data:{
                            request_type:request_type,
                            summary:summary,
                            description:description,
                            request_name:request_name,
                            deadline:deadline,
                            deadline:deadline,
                            sector:sector,
                            submission_instructions:submission_instructions,
                            buyer:buyer,
                            member:member,
                            request:request,
                            company_id:company_id
                        },
                        success:function(data){
                            new Noty({
                                type:'success',
                                layout:'top',
                                text: data.success,
                                timeout:50000
                            }).show();
                            location.reload();
                        },
                        error: function(data){
                            location.reload();
                        }
                });
            });

            //Upload company documents
            $(".btn-upload-company-document").click(function(e){
                e.preventDefault();
                var form = $("#business-document-form")[0];
                if(!form.checkValidity()){
                    form.reportValidity()
                }
                else{
                    var data = new FormData(form);
                    var url =$("input[name=upload_url]").val();
                    $(".btn-upload-company-document").prop("disabled", true);
                    $.ajax({
                        type:'POST',
                        enctype: 'multipart/form-data',
                        url:url,
                        data: data,
                        processData: false,
                        contentType: false,
                        success:function(data){
                            if(data.files.document_number !== null){
                                new Noty({
                                    type:'success',
                                    layout:'top',
                                    text: 'Document Upload Successfull!',
                                    timeout:10000
                                }).show();
                                location.reload();
                            }
                            else{
                                new Noty({
                                    type:'error',
                                    layout:'top',
                                    text: 'Document Upload Failed!',
                                    timeout:10000
                                }).show();
                                location.reload();
                            }
                        }
                    });
                }


            });

            //Upload request document
            $(".btn-add-request-document").click(function(e){
                e.preventDefault();
                var form = $("#upload-form")[0];
                if(!form.checkValidity()){
                    form.reportValidity()
                }
                else{
                    var data = new FormData(form);
                    $.ajax({
                        type:'POST',
                        url:$("input[name=get_urdoc]").val(),
                        data: data,
                        processData: false,
                        contentType: false,
                        success:function(data){
                            new Noty({
                                type:'success',
                                layout:'top',
                                text: data.success,
                                timeout:50000
                            }).show();
                            location.reload();
                        }
                    });
                }
            });

             //add request team
            $(".btn-add-request-team").click(function(e){
                e.preventDefault();
                var form = $("#add-request-team-form")[0];
                var data = new FormData(form);
                $.ajax({
                    type:'POST',
                    url:$("input[name=get_urteam]").val(),
                    data: data,
                    processData: false,
                    contentType: false,
                    success:function(data){
                        alert(data.success);
                        location.reload();
                    }
                });
            });

             //Review Bid
            $(".btn-review-bid").click(function(e){
                e.preventDefault();
                var form = $("#bid-review-form")[0];
                if(!form.checkValidity()){
                    form.reportValidity()
                }
                else{
                    var data = new FormData(form);
                    $.ajax({
                    type:'POST',
                    url:$("input[name=get_review_url]").val(),
                    data: data,
                    processData: false,
                    contentType: false,
                    success: function(data){
                        new Noty({
                            type:'success',
                            layout:'top',
                            text: data.success,
                            timeout:10000
                        }).show();
                        location.reload();
                    }
                    });
             }
            });

             //Review Bid
             $(".btn-award-decision").click(function(e){
                e.preventDefault();
                var form = $("#award-decision-form")[0];
                if(!form.checkValidity()){
                    form.reportValidity()
                }
                else{
                    var data = new FormData(form);
                    $.ajax({
                    type:'POST',
                    url:$("input[name=get_review_url]").val(),
                    data: data,
                    processData: false,
                    contentType: false,
                    success: function(data){
                        new Noty({
                            type:'success',
                            layout:'top',
                            text: data.success,
                            timeout:10000
                        }).show();
                        //window.location.href = 'buyer-requests',true;
                        location.reload();
                    }
                    });
             }
            });

            //Handle upload of request response documents
            var cnt = 0;
            $('.upload').on('change',function() {
                $(this).parent().next().val(this.value.replace(/C:\\fakepath\\/i, ''));
            });
            $('#add-upload').on('click',function(e)
            {
                cnt++;
                e.preventDefault();
                var html=`<div class="row doc-row" id="row${cnt}"><div class="col-lg-6">  <div class="form-group">    <label>Document name or title</label>    <input type="text" name="docs[filename][]" class="form-control">  </div></div><div class="col-lg-4">  <div class="form-group">      <label>Attach file</label>       <input type="file" id="FileAttachment" class="upload" name="docs[FileAttachment][]">       <input type="text" class="fileuploadurl" readonly placeholder="Maximum file size is 32MB" name="docs[fileuploadurl][]">    </div></div><div class="col-lg-2">  <button class="btn btn-xs btn-fill delete" data-id="${cnt}" id="delete-${cnt}" type="button"><i class="far fa-trash-alt"></i></button></div></div>`;
                $('#docs').append(html);
                window.componentHandler.upgradeDom();
            });

            $(document).on('click','.delete',function(e)
            {
                e.preventDefault();
                $('#row'+$(this).data('id')).remove();
            });

            //DataTable
            $(document).ready( function () {
                // $('#popup').modal('show');
                $('[data-toggle="tooltip"]').tooltip()
                $('#requests-table').DataTable();
                $('#suppliers-table').DataTable();
                $('#deadline').datepicker();
                if($('#summary').length)
                    CKEDITOR.replace('summary');
                if($('#description').length)
                    CKEDITOR.replace('description');
                if($('#buyer_remarks').length)
                    CKEDITOR.replace('buyer_remarks');
                if($('#product_description').length)
                    CKEDITOR.replace('product_description');
                if($('#support_areas').length)
                    CKEDITOR.replace('support_areas');
                if($('#new_biz_proposal').length)
                    CKEDITOR.replace('new_biz_proposal');
                // if($('#physical_location').length)
                //     CKEDITOR.replace('physical_location');

                $(".accordion-header").click(function(event) {
                    event.preventDefault();
                    var dis = $(this);
                    var acr_box = dis.closest(".accordion");
                    if(dis.hasClass("active-accordion") != true){
                        //acr_box.find(".accordion-header").removeClass("active-accordion");
                        dis.addClass("active-accordion");
                        //acr_box.find(".accordion-content").hide();
                        dis.next().show();
                    }else{
                        dis.removeClass("active-accordion");
                        dis.next().hide();
                    }
                });

                $('.other_container').hide();
                $(document).on('click','#other_business_needs', function(){
                    if(this.checked == true) {
                        $('.other_container').show();
                    }else{
                        $('.other_container').hide();
                    }
                });

                $('button .toggleResourcesForm').click(function(){
                    alert("dsdss");
                    $("#resourcesForm").toggle();
                });


                // if($('#tel').length){
                //     var input = document.querySelector("#tel");
                //     if(input!==null){
                //         window.intlTelInput(input, {
                //             initialCountry: "auto",
                //             geoIpLookup: function(callback) {
                //                 $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
                //                 var countryCode = (resp && resp.country) ? resp.country : "us";
                //                 callback(countryCode);
                //                 });
                //             },
                //             utilsScript:"https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js"
                //         });
                //     }
                // }
                if($('#telephone').length){
                    var input2 = document.querySelector("#telephone");
                    window.intlTelInput(input2, {
                        initialCountry: "auto",
                        geoIpLookup: function(callback) {
                            $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
                            var countryCode = (resp && resp.country) ? resp.country : "us";
                            callback(countryCode);
                            });
                        },
                        utilsScript:"https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js"
                    });
                }
                if($('#mobile').length){
                    var input3 = document.querySelector("#mobile");
                    window.intlTelInput(input3, {
                        initialCountry: "auto",
                        geoIpLookup: function(callback) {
                            $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
                            var countryCode = (resp && resp.country) ? resp.country : "us";
                            callback(countryCode);
                            });
                        },
                        utilsScript:"https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js"
                    });
                }
                if($('#ctelephone').length){
                    var input4 = document.querySelector("#ctelephone");
                    window.intlTelInput(input4, {
                        initialCountry: "auto",
                        geoIpLookup: function(callback) {
                            $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
                            var countryCode = (resp && resp.country) ? resp.country : "us";
                            callback(countryCode);
                            });
                        },
                        utilsScript:"https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js"
                    });
                }



            });

            // \

            $('.company-shareholder').hide();
            $('#type').change(function(){
                if($(this).val() == 'Company'){
                    $('.company-shareholder').show();
                }else{
                    $('.company-shareholder').hide();
                }
            });

            $('#share_percentage').change(function(){
                $.ajax({
                    type:'GET',
                    url:'check-shares/'+$("input[name=company_id]").val()+'/'+$(this).val(),
                    success:function(e){
                        $('.percent_msg').html(e.message);
                    }
                });
            });

            $('#local_content_policy').change(function(){
                if($(this).val() == 1){
                    $('.local_content_policy_file').show();
                }
                else{
                    $('.local_content_policy_file').hide();
                }
            });

            //Check if company document type expires
            $('#doc_type').change(function(){
                var expires_id = $(this).val() + '-expires';
                if($('#'+expires_id).val() == 1){
                    $('#expiry_container').removeClass('d-none');
                }else{
                    $('#expiry_container').addClass('d-none');
                }

                $.ajax({
                    type:'GET',
                    url:'/dashboard/check-company-document-exists/'+$("input[name=company_id]").val()+'/'+$(this).val(),
                    success:function(response){
                        if(response.exists){

                            $('.document-upload-field').hide();
                            $('.btn-upload-company-document').prop('disabled', true);
                            alert('Document already uploaded!', false);
                            return false;

                        }
                        else{
                            $('.document-upload-field').show();
                            $('.btn-upload-company-document').prop('disabled', false);
                        }
                    }
                });


            });

            $('#request_sector').multiselect({
                nonSelectedText: 'Select sector for this request',
                buttonClass: 'custom-select',
                enableFiltering: true,
                enableCaseInsensitiveFiltering: true,
                includeFilterClearBtn: false,
                buttonContainer: '<div class="btn-group" />',
                buttonWidth:'350px',
                numberDisplayed: 7,
            });
            //Load subsectors
            //$('#sub_sector').hide();
            $('#sector').change(function(){
                $('#sub_sector').removeAttr('multiple');
                $('#sub_sector').empty();
                var sector = $(this).val();
                $.ajax({
                    type:'GET',
                    url:'/utils/load-sub-sectors/'+$(this).val(),
                    success:function(response){
                        $('#sub_sector').multiselect('destroy');
                        if(response.exists == true){
                            $('#sub_sector').attr('multiple', 'multiple');
                            $('#sub_sector').attr('required', 'required');
                            response.sectors.forEach(sector => {
                                $('#sub_sector').append('<option value="'+ sector.id +'">'+ sector.name +'</option>');
                            });
                            $('#sub_sector').multiselect({
                                nonSelectedText: 'Please Select Sub-sector',
                                buttonClass: 'custom-select',
                                enableFiltering: true,
                                enableCaseInsensitiveFiltering: true,
                                includeFilterClearBtn: false,
                                buttonContainer: '<div class="btn-group" />',
                                buttonWidth:'350px',
                                numberDisplayed: 7,
                                onChange: function(option, checked, select) {
                                    if(checked){
                                        if($('#sub_sector option:selected').length > 3 ){
                                            alert('You can only select 3 subsectors!');
                                            $('#sub_sector').multiselect('deselect', $(option).val());
                                        }
                                    }
                                }
                            });

                        }else{
                            $('#sub_sector').removeAttr('multiple');
                            $('#sub_sector').removeAttr('required');
                        }

                    }
                });
            });

            $('.timer').each(function() {
                    var $this = $(this),
                        countTo = $this.attr('data-to');
                    $({ countNum: $this.text()}).animate({
                        countNum: countTo
                    },
                    {
                        duration: 3000,
                        easing:'linear',
                        step: function() {
                        $this.text(Math.floor(this.countNum));
                        },
                        complete: function() {
                        $this.text(this.countNum);
                        //alert('finished');
                        }
                    });
            });
            $(document).on('click','.promo', function(e) {
                e.preventDefault();
                // alert('clicked');
                $('.promo-code-input').toggle();
            });




            document.head.appendChild(recaptchaScript);
        }

    }
</script>
