// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-15.use[1]!../../../node_modules/flexslider/flexslider.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flexslider{height:465px;width:100%}.flexslider .slides>li{-webkit-backface-visibility:hidden;display:block}", "",{"version":3,"sources":["webpack://./resources/js/components/SliderComponent.vue"],"names":[],"mappings":"AAmCA,YAEI,YAAA,CADA,UAhCJ,CAmCA,uBAEI,kCAAA,CADA,aA/BJ","sourcesContent":["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n@import \"~flexslider/flexslider.css\"\n.flexslider\n    width: 100%\n    height:465px\n\n.flexslider .slides > li\n    display: block\n    -webkit-backface-visibility: hidden\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
