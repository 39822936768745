/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('flexslider');
require('jquery-ui');
require('blueimp-file-upload');
require('datatables.net');
require('datatables.net-dt');
require('bootstrap-datepicker');
require('./bootstrapMultiselect');
import intlTelInput from 'intl-tel-input';
window.intlTelInput = intlTelInput;
//require('ckeditor4');
import axios from 'axios'
import Vue from 'vue'
//window.Vue = require('vue');
window.CKEDITOR_BASEPATH = '../../node_modules/ckeditor4/';
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

//Vue.prototype.$http = axios
Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('slider-component', require('./components/SliderComponent.vue').default);
Vue.component('pillar-component', require('./components/PillarsComponent.vue').default);
Vue.component('news-component', require('./components/NewsComponent.vue').default);
Vue.component('page-component', require('./components/PageComponent.vue').default);
Vue.component('kustomer', require('./components/Kustomer/Kustomer.vue').default);
Vue.component('royalty-badge', require('./components/RoyaltyBadge.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
