<template>

</template>

<script>
export default {
    name: 'pillar-box',
    data(){
        return{
        pillars: [],
        }
    },
    mounted(){
        $('.pillar-box').click(function(){
            window.location.href   =    $(this).find('.pillar-hover').attr('href');
        });
    }
}
</script>
<style lang="sass">
    .pillar-list
        width: 100%

    .pillar-image
        background-image: url(https://biasharanow.com/images/markets-cropped.jpg)

    .pillar-image-skills
        background-image: url(https://biasharanow.com/images/skills-cropped.jpg)

    .pillar-image-finance
        background-image: url(https://biasharanow.com/images/finance-cropped.jpg)

    .pillar-hover
        background-color: #0072ce

    .pillar-hover:hover
        text-decoration:none

    .pillar
        float: left
        width: 100%
        height: 200px
        padding: 40px 20px 20px 20px
        position: relative
        text-align: center
        overflow: hidden
        transition: all 1s ease
        background-color: #0072ce
        color:#fff

    .pillar a
        color: #fff

    .pillar-image
        display: inline-block
        width: 110px
        height: 110px
        background-size: contain
        background-position: center center
        background-repeat: no-repeat
        border-top-left-radius: 50% 50%
        border-top-right-radius: 50% 50%
        border-bottom-right-radius: 50% 50%
        border-bottom-left-radius: 50% 50%

    .pillar-image-finance
        display: inline-block
        width: 110px
        height: 110px
        background-size: contain
        background-position: center center
        background-repeat: no-repeat
        border-top-left-radius: 50% 50%
        border-top-right-radius: 50% 50%
        border-bottom-right-radius: 50% 50%
        border-bottom-left-radius: 50% 50%

    .pillar-image-skills
        display: inline-block
        width: 110px
        height: 110px
        background-size: contain
        background-position: center center
        background-repeat: no-repeat
        border-top-left-radius: 50% 50%
        border-top-right-radius: 50% 50%
        border-bottom-right-radius: 50% 50%
        border-bottom-left-radius: 50% 50%

    .pillar:hover .pillar-hover
        transform: translate(0,0)

    .pillar-arrow
        position: absolute
        bottom: 20px
        right: 20px
        font-size: 30px

    .pillar-hover
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        transform: translate(0,100%)
        transition: all 1s ease
        padding: 30px


</style>

