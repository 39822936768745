<template>

</template>

<script>
export default {
    name: 'news-section',
    data(){
        return{
        pillars: [],
        }
    },
    methods:{
        getSlider: function(){
            //const baseURI = window.location.origin+'/slides'
            const baseURI = 'http://localhost/appmr/public/slides';
            let result = this.$http.get(baseURI).then(result=>{
            if(result.status==200){
                this.slides=result.data;
            }
            }).catch(err=>{
                console.log(err)
            });
        }
    },
    mounted(){
        $('.pillar-box').click(function(){
            window.location.href   =    $(this).find('.pillar-hover').attr('href');
        });
    }
}
</script>
<style lang="sass">
    .home-news
        background: #fff

    .news .blog-date
        position: absolute
        display: block
        padding: 10px
        background: #093eb6
        top: 12px
        right: 12px
        z-index: 100

    .blog-img-box
        position: relative
        display: block

    .blog-img-box a.hover-effect
        z-index: 0
        width: 100%

    .blog-img-box img
        width: 100%
        height: 200px

    span.month
        display: block
        font-size: 13px
        color: rgba(255, 255, 255, 0.78)
        margin-bottom: 8px

    span.date
        font-size: 28px
        font-family: "Roboto Condensed"
        color: #ffffff
        font-weight: bold
        line-height: 1

</style>

