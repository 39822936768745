<template>
    <div class="main">
         <div class="banner">

        </div>
    </div>
</template>

<script>
import 'jquery-ui/ui/widgets/tabs.js';
export default {
    name: 'pageContent',
    // data(){
    //     return{

    //     }
    // },
    methods:{

    },
    mounted(){
        window.jQuery( ".uou-tabs" ).tabs().addClass( "ui-helper-clearfix" );
        window.jQuery( ".uou-tabs  li" ).removeClass( "ui-corner-top" ).addClass( "ui-corner-left" );
        // window.jQuery(".uou-tabs  li").click(function(){
        //     $(".uou-tabs").tabs(this,"active",1);
        // });
    }
}
</script>
<style lang="sass">

</style>

