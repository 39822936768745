import { render, staticRenderFns } from "./RoyaltyBadge.vue?vue&type=template&id=40732573&scoped=true"
import script from "./RoyaltyBadge.vue?vue&type=script&lang=js"
export * from "./RoyaltyBadge.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40732573",
  null
  
)

export default component.exports